import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import CurrencyInput from '../../../shared/CurrencyInput';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';
type CurrencyInputActionData = {
  allowChange: boolean;
  currencyCode: string;
};

export type CurrencyInputActionResponse = {
  currencyCode: string;
  amount: string;
};

type CurrencyInputActionProps = ActionBaseProps<CurrencyInputActionResponse, CurrencyInputActionData>;

const CurrencyInputAction: FC<CurrencyInputActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { onAnswer, onValid, readOnly, currentAction } = useFormAction(props);
  const [currencyCode, setCurrencyCode] = useState(response?.currencyCode || data.currencyCode || 'EUR');

  const onChange = useCallback(
    (answer: CurrencyInputActionResponse) => {
      setCurrencyCode(answer?.currencyCode);
      onValid(!!answer?.amount);
      onAnswer(answer.amount ? answer : null);
    },
    [onAnswer, onValid],
  );

  return (
    <div className="flex flex-col" data-cy="number-input-action">
      <ActionTitleDescription required={required} {...data} />

      <div className="w-[350px]" aria-labelledby={`question-title-${currentAction.id}`} aria-describedby={`question-description-${currentAction.id}`}>
        <CurrencyInput
          value={{ amount: response?.amount || '', currencyCode: currencyCode }}
          onChange={onChange}
          placeholder={t('currency-input.placeholder')}
          disabled={readOnly}
          canChange={data.allowChange}
        />
      </div>
    </div>
  );
};

export default CurrencyInputAction;
